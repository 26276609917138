/**
 * Naming conventions
 * - u: utility
 * - c: component
 * - o: object
*/

.u-UsageBar__error {
  color: #ff2525;
  font-weight: bold;
  letter-spacing: 0.3pt;
  line-height: 1.5em;
  text-decoration: underline;
}
.u-UsageBar-light {
  --text-color: hsl(0, 0%, 100%);
  --background-tooltip-color: hsl(0, 0%, 13%);
  --background-bar-color: hsl(0, 0%, 93%);
}
.u-UsageBar-dark {
  --text-color: hsl(0, 0%, 13%);
  --background-tooltip-color: hsl(0, 0%, 100%);
  --background-bar-color: hsl(0, 0%, 30%);
}

.c-UsageBar {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: inherit;
  color: inherit;
  font-size: 1em;
  padding: 8pt;
  position: relative;
  height: 60pt;
  width: 100%;
}
.o-UsageBar__bar {
  position: relative;
  height: 8pt;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: inherit;
  background-color: var(--background-bar-color);
  border-radius: 4pt;
}
.o-UsageBar__bar__element:not(:first-of-type) {
  border-left: 1px solid var(--background-bar-color);
}
.o-UsageBar__bar__element:not(:last-of-type) {
  border-right: 1px solid var(--background-bar-color);
}
.o-UsageBar__bar__element:first-of-type {
  border-radius: 4pt 0 0 4pt;
}
.o-UsageBar__bar__element:last-of-type {
  border-radius: 0 4pt 4pt 0;
}
.o-UsageBar__bar__element:nth-child(odd) > .o-UsageBar__bar__tooltip {
  top: -26pt;
}
.o-UsageBar__bar__element:nth-child(odd) > .o-UsageBar__bar__tooltip::after {
  border-top: 5pt solid var(--background-tooltip-color);
  bottom: -4pt;
}
.o-UsageBar__bar__element:nth-child(even) > .o-UsageBar__bar__tooltip {
  bottom: -26pt;
}
.o-UsageBar__bar__element:nth-child(even) > .o-UsageBar__bar__tooltip::after {
  border-bottom: 5pt solid var(--background-tooltip-color);
  top: -4pt;
}
.o-UsageBar__bar__element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  height: 100%;
}
.o-UsageBar__bar__tooltip {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
  padding: 5pt;
  border-radius: 4pt;
  background-color: var(--background-tooltip-color);
  color: var(--text-color);
  font-size: 0.9em;
  line-height: 1;
}
.o-UsageBar__bar__tooltip__percentage {
  opacity: 0.78;
  font-size: 0.9em;
  margin: 0 0 0 3pt;
}
.o-UsageBar__bar__tooltip::after {
  z-index: 1;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 4pt solid transparent;
  border-right: 4pt solid transparent;
}

/** Compact bar css rules */

.c-UsageBar__compact {
  height: auto !important;
}
.o-UsageBar__compact__bar {
  max-width: 500px;
}
.o-UsageBar__bar__elements__labels__container {
  margin-top: 12pt;
  position: relative;
  width: 100%;
  max-width: 480px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4pt;
  font-size: 0.9em;
}
.o-UsageBar__bar__elements__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
.o-UsageBar__bar__elements__label > span,
.o-UsageBar__bar__elements__label > .o-UsageBar__bar__tooltip__percentage {
  color: var(--background-tooltip-color);
}
.o-UsageBar__bar__elements__label--dot {
  border-radius: 50%;
  margin-right: 6pt;
  height: 8pt;
  width: 8pt;
}
